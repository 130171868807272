.chatbox_wrapper {
    margin: 2rem 1rem;
}

.sndbtndisabled {
    pointer-events: none;
    opacity: 0.5;
}

.chatbox_wrapper .chat_box {
    background-color: #ffffff;
    border: 1px solid #707070;
    border-radius: 13px;
}

.chatbox_container {
    display: flex;
    gap: 2rem;
}

.chatbox_container .chatbox_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
    gap: 1rem;
}

.chatbox_container .chatbox_head h2 {
    font-weight: 600;
    color: #000;
    white-space: nowrap;
}

.chatbox_container .chatbox_head .create_btn_div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.chatbox_container .chatbox_head .chatbox_head_left {
    display: flex;
    align-items: center;
}

.chatbox_container .chatbox_head .chatbox_head_left h2 {
    margin: 0;
}

.chatbox_container .chatbox_head .create_btn_div .show_all_chatbots {
    color: #000;
    font-weight: 500;
    text-decoration: none;
    white-space: nowrap;
}

.chatbox_container .chatbox_head .create_btn_div .create_new_chatbot {
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
    background-color: #000;
    border-radius: 20px;
    padding: 0.3rem 0.8rem;
    white-space: nowrap;
}

.chat_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1rem 0.5rem 1rem;
    height: 450px !important;
    position: relative;
}

.chat_box .msg_div {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 12px;
}

.received_msg,
.sent_msg {
    display: flex;
    align-items: center;
    border: 1px solid #707070;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #F8F8F8;
    margin-bottom: 1rem;
}

.received_msg p,
.sent_msg p {
    margin: 0;
    font-weight: 500;
}

.received_msg img,
.sent_msg img {
    width: 20px;
    margin-right: 0.3rem;
}

.received_msg {
    align-self: flex-start;
    max-width: 80% !important;
}

.sent_msg {
    align-self: flex-end;
    max-width: 80% !important;
}

.send_msg_input textarea {
    width: 100%;
    height: 50px;
    padding: 0.5rem 130px 0.5rem 1rem;
    border-radius: 5px;
    border: none;
    background-color: #F8F8F8;
    box-shadow: 0 .3rem 0.2rem rgba(0, 0, 0, .15) !important;
}

@media screen and (max-width: 450px) {
    .send_msg_input textarea {
        font-size: 14px;
    }
}

.expanded {
    height: auto;
    max-height: 8em;
    /* Adjust the maximum height as needed */
}

.send_msg_input {
    position: relative;
}

.send_msg_input .send_btn {
    height: 40px;
    background-color: #800080;
    width: 120px;
    /* margin-bottom: 0.5rem; */
    position: absolute;
    bottom: 12px;
    right: 8px;
}

.chatbox_container .sidebar_toggle_btn {
    background-color: #800080 !important;
    color: #ffffff !important;
    width: 70px;
    text-align: right;
    border: none;
    position: relative;
    left: -38px;
}

.chatbox_container .sidebar_toggle_btn:active {
    background-color: #800080 !important;
    color: #ffffff !important;
    border: none !important;
}

.rotate_arrow {
    transition: all 0.4s ease-in-out;
}

.rotated_arrow {
    transform: rotate(180deg);
}

@media screen and (max-width: 1250px) {
    .received_msg {
        max-width: 90% !important;
    }

    .sent_msg {
        max-width: 90% !important;
    }
}


@media screen and (max-width: 1100px) {
    .received_msg {
        max-width: 95% !important;
    }

    .sent_msg {
        max-width: 95% !important;
    }
}

@media screen and (max-width: 991px) {

    .chatbox_container {
        gap: 0;
    }
}

@media screen and (max-width: 700px) {
    .chatbox_head {
        flex-direction: column;
        gap: 1.5rem;
    }

    .chatbox_head_left {
        align-items: flex-start;
        width: 100%;
    }

    .create_btn_div {
        width: 100%;
        justify-content: space-between;
        padding: 0 1rem;
    }
}

@media screen and (max-width: 576px) {
    .send_msg_input .send_btn {
        width: auto;
    }

    .send_msg_input textarea {
        padding-right: 60px;
    }
}

@media screen and (max-width: 540px) {
    .chatbox_container .sidebar_toggle_btn {
        width: 70px;
    }

    .send_msg_input .send_btn {
        width: auto;
    }
}