.interface_container {
    background: linear-gradient(to bottom, rgba(128, 0, 128, 10%), rgba(88, 219, 154, 10%));
    border-radius: 20px;
    padding: 2rem;
    margin: 2rem 0;
}

.interface_container h3 {
    font-weight: 700;
}

.interface_setting_div {
    margin: 3rem 0;
}

.interface_container .chat_page {
    border: 1px solid #707070;
    border-radius: 8px;
    height: 600px;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    position: relative;
}

.chat_page_head {
    background-color: #000;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.chat_page_head .welcome_heading {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 1rem;
}

.chat_page_head .welcome_heading h4,
.chat_page_head .welcome_heading p {
    margin: 0;
    color: #ffffff;
}

.chat_page_head .welcome_heading h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.chat_page_head .welcome_heading p {
    font-size: 14px;
}

.interface_container .chat_page .msg_div {
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
}

.interface_container .chat_page .msg_div .sent_msg,
.interface_container .chat_page .msg_div .received_msg {
    font-size: 13px;
    width: fit-content;
    margin-bottom: 2.2rem;
    max-width: 300px;
}

.interface_container .chat_page .msg_div .sent_msg {
    align-self: flex-end;
    background-color: #800080;
    color: #ffffff;
}

.interface_container .chat_page .msg_div .received_msg {
    align-self: flex-start;
    background-color: #EEF1F3;
}

.interface_container .chat_page .send_msg_input_div {
    padding: 1rem;
}

.interface_container .chat_page .send_msg_input {
    position: relative;
    margin-bottom: 1rem;
}

.interface_container .chat_page .send_msg_input input {
    height: 50px;
    border: 1px solid #707070;
    border-radius: 8px;
    width: 100%;
}

.interface_container .chat_page .chat_send_btn {
    position: absolute;
    top: 4px;
    right: 12px;
}

.branding_div_chat {
    text-align: center;
}

.branding_div_chat p {
    margin: 0;
    font-size: 12px;
    color: #272727;
    font-weight: 500;
}

.learn_more_chat {
    padding: 0 1rem;
}

.learn_more_chat p {
    padding-left: 1rem;
    margin-bottom: 0.2rem;
    color: #707070;
}

.learn_more_chat .collapse_body p {
    padding: 0 0.3rem;
}

.learn_more_chat .collapse_body {
    padding: 0.1rem;
    margin: 0;
    margin-left: 1rem;
    width: fit-content !important;
    border: 1px solid #707070;
    border-radius: 10px;
}

.interface_setting_div .header_settings,
.interface_setting_div .link_suggestion_setting {
    background-color: #ffffff;
    border-radius: 30px;
    border-top-right-radius: 0;
    padding: 1rem;
}

.interface_setting_div h6 {
    font-weight: 700;
    color: #F58634;
    margin-bottom: 1rem;
}

.interface_setting_div .header_settings .setting_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 0.5rem;
}

.user_msg_color p,
.interface_setting_div .header_settings .setting_flex p {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
}


.interface_setting_div .header_settings .setting_flex input {
    border-radius: 20px;
    border: none;
    background-color: #EEF1F3;
    font-size: 14px;
    padding: 0.2rem;
    padding-left: 1rem;
    text-align: center;
}

.interface_setting_div .header_settings .setting_flex label {
    border-radius: 20px;
    border: none;
    background-color: #EEF1F3;
    font-size: 14px;
    width: 178px;
    text-align: center;
    padding: 0.2rem;
}

.user_msg_color .color_input,
.interface_setting_div .header_settings .setting_flex .color_input {
    padding: 0;
    width: 75px;
    height: 24px;
    cursor: pointer;
}

.user_msg_color .color_input::-webkit-color-swatch,
.interface_setting_div .header_settings .setting_flex .color_input::-webkit-color-swatch {
    border-radius: 20px;
}

.link_suggestion_setting {
    text-align: center;
    margin-top: 1.5rem;
}

.link_suggestion_setting input {
    border-radius: 20px;
    border: none;
    background-color: #EEF1F3;
    font-size: 14px;
    padding: 0.4rem;
    text-align: center;
}

.input_placeholder_setting {
    margin-top: 2rem;
}

.user_msg_color {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 0.5rem;
}

.user_msg_color_div {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.suggested_msgs {
    display: flex;
    justify-content:flex-end;;
    margin-bottom: 0.5rem;
}

.suggested_msgs_flex {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.suggested_msgs_flex div {
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 0.5rem;
}

.suggested_msgs_flex div p {
    margin: 0;
}

.suggested_msg_setting_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.suggested_msg_setting_head p {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 20px;
    cursor: pointer;
}

.suggested_msg_setting input {
    width: 100%;
    margin-bottom: 0.5rem;
}

.suggestion_input {
    text-align: left !important;
    padding-left: 1rem !important;
}

.suggestion_input_div {
    position: relative;
}

.suggestion_input_dlt {
    width: 0.8rem;
    position: absolute;
    top: 8px;
    right: 16px;
    cursor: pointer;
}

.first_msg_setting,
.user_msg_setting,
.suggested_msg_setting,
.chat_bubble {
    border-radius: 30px !important;
    border-top-left-radius: 0 !important;
}

.floating_icon_setting .floating_icon {
    background-color: #272727;
    width: fit-content;
    padding: 0.8rem;
    border-radius: 50%;
    margin-top: 2rem;
}

.floating_icon_setting .floating_icon_div {
    display: flex;
    justify-content:flex-end;;
    width: 100%;
    gap: 1.5rem;
}

.header_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 7%;
    top: 5%;
    left: -6%;
}

.link_suggestion_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 14%;
    top: 52.5%;
    left: -6%;
}

.placeholder_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 10%;
    bottom: 13%;
    left: -6%;
}

.first_msg_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 33%;
    top: 15%;
    right: -5%;
}

.user_msg_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 10%;
    top: 27%;
    right: -6%;
}

.bot_msg_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 27%;
    bottom: 50%;
    right: -23px;
}

.suggested_msg_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 10%;
    bottom: 22%;
    right: -6%;
}

.chat_bubble {
    position: relative;
}

.chat_bubble_pointer {
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    height: 4px;
    width: 9%;
    top: 26%;
    left: -10%;
}

.interface_update_btn_div {
    text-align: center;
    margin: 3rem 0 0 0;
}

.interface_update_btn_div .interface_update_btn {
    background-color: #F58634;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    width: 80%;
}

@media screen and (max-width: 1400px) {

    .user_msg_color p,
    .interface_setting_div .header_settings .setting_flex label,
    .interface_setting_div .header_settings .setting_flex p,
    .interface_setting_div .header_settings .setting_flex input {
        font-size: 12px;
    }
}

@media screen and (max-width: 991px) {
    .dropdown_container {
        display: block;
    }

    .triangle_pointer {
        display: none;
    }

    .dropdown_container .profile_menu {
        margin-left: auto !important;
    }
}

.welcome_heading img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.chat_page_head .welcome_heading p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.input_size {
    color: #707070;
}