.mui-datatables-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    opacity: 0;
}

.mui-datatables-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin-top: 1.5rem;
    margin-right: .2rem;
}

.mui-datatables-levciy-MuiTablePagination-displayedRows,
.mui-datatables-pdct74-MuiTablePagination-selectLabel {
    margin: 0;
}

.tss-1832qqn-MUIDataTableHeadRow-root {
    background-color: #ffffff;
    border: 2px solid #000;
    margin-bottom: 1rem !important;
    border-radius: 5px !important;
}

.mui-datatables-11mde6h-MuiPaper-root {
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    border-radius: none !important;
}

.tss-1akey0g-MUIDataTableHeadCell-data {
    font-size: 18px;
    font-weight: 800 !important;
}

.tss-tlx3x1-MUIDataTableToolbar-root {
    margin-bottom: 2rem !important;
}

.mui-datatables-1ygcj2i-MuiTableCell-root {
    padding: 0.5rem !important;
}

.mui-datatables-1q1u3t4-MuiTableRow-root {
    background-color: #FDF2FF;
    border: 1px solid #a5a5a5;
}

.mui-datatables-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #f9e1ba !important;
    font-weight: 500 !important;
}

.mui-datatables-2ulfj5-MuiTypography-root {
    font-size: 28px !important;
    font-weight: 600 !important;
}

.active_disable_buttons {
    display: flex;
    gap: 1rem;
}

.active_disable_buttons .active_btn {
    background-color: #37621b;
    color: #ffffff;
    font-weight: 400;
    border: none;
    border-radius: 6px;
    padding: 0.2rem 1rem;
}

.active_disable_buttons .disable_btn {
    background-color: #de1015;
    color: #ffffff;
    font-weight: 400;
    border: none;
    border-radius: 6px;
    padding: 0.2rem 1rem;
}

.active_disable_buttons .active_btn_fade {
    background-color: #c5eca5 !important;
    color: #ffffff;
    font-weight: 400;
    border: none;
    border-radius: 6px;
    padding: 0.2rem 1rem;
}

.active_disable_buttons .disable_btn_fade {
    background-color: #ffa8a8 !important;
    color: #ffffff;
    font-weight: 400;
    border: none;
    border-radius: 6px;
    padding: 0.2rem 1rem;
}

.custom-excel-button {
    border: none;
    outline: none;
}

.admin_subscriber_head .sidebar_toggle_btn {
    background-color: #FDF2FF;
    /* background-color: #000; */
    width: 100px;
    text-align: end;
    position: relative;
    left: -40px;
    align-self: flex-start;
}

