.privacy_policy_container h3 {
    font-weight: 600;
    margin: 2rem 0 1rem 0;
    color: #2F2F2F !important   ;
}
.privacy_policy_container h1 {
    color: #2F2F2F !important;
    margin: 3rem 0 0 0;
}

.privacy_policy_container p {
    font-size: 17px;
    color: #696969;
    font-weight: 500;
    line-height: 26px
}

.privacy_policy_container a {
    color: #800080 !important;
    text-decoration: underline;
}

.privacy_policy_container {
    margin-bottom: 5rem !important  ;
}