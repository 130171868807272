.admin_header_container .dropdown_container .profile_img img {
    width: 2.5rem;
}

.admin_header_container .dropdown_container .dropdown-toggle::after {
    display: none;
}

.admin_header_container #navbarNavAltMarkup ul {
    width: auto;
    align-items: center;
    gap: 3rem;
}

.admin_header_container .nav_bg {
    padding: 0.9rem 4rem !important;
}

hr {
    border-top: var(--bs-border-width) dotted !important;
}


.admin_header_container .dropdown_container .admin_profile_menu:focus{
    outline: none !important;
    border: none !important;
}

.admin_header_container .dropdown_container .admin_profile_menu .profile_img div{
    font-weight: 500;
}

.admin_header_container .dropdown_container .dropdown-menu {
        margin-top: 1.1rem;
        right: -34px;
        width: 250px;
        text-align: end;
        padding: 1rem 0.5rem;
        border: none;
}

.admin_header_container .dropdown_container .dropdown-menu .triangle_pointer {
    width: 14px;
    height: 18px;
    border-top: solid 13px #676767;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    position: absolute;
    top: -20px;
    right: 48px;
}

.admin_header_container .customer_support_message_btn {
    background-color: #ffffff;
    border: 2px solid #000;
    display: flex;
    gap: 0.5rem;
    font-weight: 500;
}

.admin_header_container .customer_support_message_btn p {
    margin: 0;
}

.customer_support_message_count_div {
    background-color: red;
    height: fit-content;
    width: fit-content;
    padding: 0 0.5rem;
    border-radius: 50%;
}

.customer_support_message_count_div p {
    color: #ffffff;
    font-weight: 400;
}

@media screen and (max-width: 768px)  {
    .admin_header_container .nav_bg {
        padding: 0.9rem 2rem !important;
    }
}