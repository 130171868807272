.email_verify_box_wrapper {
    display: flex;
    justify-content: center;
}

.email_verify_box_container {
    width: 370px;
    height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 10px;
    border-bottom: 3px solid #800D80;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.email_verify_box_container h4{
    margin-bottom: 1.5rem;
}

/* .header {
    display: none !important;
} */