.sidebar.admin_sidebar {
    background-color: #FDF2FF;
    border-radius: 10px;
    border: none !important;
    width: 250px;
    transition: all 0.4s ease-in-out;
    padding-top: 1rem;
    z-index: 10;
    height: 100%;
}

.admin_logout_btn {
    margin-top: 3rem;
    margin-left: 1rem;
}

.admin_logout_btn img {
    width: 1.8rem;
}

.admin_logout_btn p {
    font-weight: 500;
}

.admin_sidebar_body .admin_sidebar_body_container ul .list_item p {
    font-size: 18px;
}

.admin_sidebar_body .admin_sidebar_body_container ul .list_item img {
    width: 1.2rem;
    margin-right: 0.3rem;
}

.admin_sidebar_body_container .active {
    background-color: #F58634;
    border-radius: 20px;
    padding: 0.2rem 0.5rem;
}

.admin_sidebar_body_container .active .svg-icon.active_icon {
    filter: invert(100%) sepia(0%) saturate(0%);
  }

  .dropdown_sidebar, #flush-collapseOne{
    background-color: #FDF2FF !important;
  }

  #flush-collapseOne{
    font-size: 16px !important;
  }

  .admin_sidebar_body_container .accordion_head button {
    font-size: 18px;
    padding: 0;
  }

  .admin_sidebar_body_container .accordion-body ul {
    margin-top: 0.5rem;
  }

  .admin_sidebar_body_container .accordion-body ul li {
    margin: 1rem 0;
    font-size: 18px;
  }