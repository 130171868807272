.home {
    background-color: #EEF1F3;
    z-index: -11;
}

.home_container .home_head {
    text-align: center;
    margin: 4rem 0 0 0;
    position: relative;
}

.home_container .home_head::after {
    content: '';
    background-image: url('../Assests/images/rounds.png');
    background-size: cover;
    width: 300px;
    height: 300px;
    position: absolute;
    bottom: -22%;
    right: -9%;
    z-index: 0;
}

.home_container .home_head .color_text {
    font-weight: 800;
    margin: 0.5rem 0 1.5rem 0;
}

.home_container .home_head .text_orng {
    color: #F58634;
}

.home_container .home_head .text_purple {
    color: #800080;
}

.home_container .head_img_btn img {
    width: 100%;
    height: 100%;
    margin-top: -5rem;
}

.home_container .head_img_btn a {
    text-decoration: none;
}

.home_container .head_img_btn .lets_start_btn_div {
    position: relative;
    top: 60px;
    right: auto;
    left: auto;
    display: flex;
    justify-content: center;
}

.home_container .head_img_btn .lets_start_btn p {
    margin: 0;
}

.home_container .head_img_btn .lets_start_btn {
    background-image: linear-gradient(to right, #F58634, #751A7B);
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffffff;
    font-size: 15px;
    padding: 0.7rem 1.3rem;
    border: none;
    border-radius: 30px;
    vertical-align: bottom;
}

.rounds_1 img,
.rounds_2 img {
    overflow: hidden;
    height: 400px;
    width: 400px;
    z-index: -1;
}

.rounds_3 img {
    overflow: hidden;
    height: 800px;
    width: 800px;
    z-index: -2;
}

.home_container {
    position: relative;
    overflow: hidden;
}

@media screen and (max-width: 1450px) {
    .home_container .head_img_btn img {
        margin-top: -3rem;
    }
}

@media screen and (max-width: 768px) {
    .home_container .head_img_btn .lets_start_btn_div {
        position: relative;
        top: 20px;
        right: auto;
        left: auto;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .home_container .home_head h1 {
        font-size: 24px;
    }

    .home_container .home_head h5 {
        font-size: 16px;
    }

    .home_container .home_head .color_text {
        margin: 0.5rem 0 1rem 0;
    }

    .home_container .head_img_btn .lets_start_btn {
        gap: 0.5rem;
        font-size: 12px;
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 440px) {
    .home_container .home_head {
        text-align: center;
        margin: 3rem 0 0 0;
    }

    .home_container .home_head h1 {
        font-size: 20px;
    }

    .home_container .home_head h5 {
        font-size: 12px;
    }

    .home_container .home_head .color_text {
        margin: 0.5rem 0 1rem 0;
    }
}

/* ------------------------------------Head Section Styling--------------------------------------- */

/* ------------------------------------Main Feature Section Styling--------------------------------------- */

Section.main_feature {
    margin-top: 3rem;
}

.main_feature_container .heading_text {
    text-align: center;
    margin: 5rem 0;
    margin-bottom: 3rem;
}

.main_feature_container .heading_text h1 {
    font-weight: 700;
}

Section.main_feature .main_feature_container .card img {
    width: 5rem;
    margin: auto;
}

section.main_feature .main_feature_container .card {
    border: none;
    background-color: transparent;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    z-index: 10;
}

Section.main_feature .main_feature_container .card .card-title {
    font-weight: 700;
    font-size: 24px;
}

Section.main_feature .main_feature_container .card .card-text {
    font-weight: 500;
}

/* ------------------------------------Main Feature Section Styling--------------------------------------- */

/* ------------------------------------Demo Video Section Styling--------------------------------------- */
section.demo_div {
    margin-top: 4rem;
}

.demo_div_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.demo_div_container .demo_headings {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.border_bottom_heading {
    height: 4px;
    background: linear-gradient(to right, #800080, #F58634);
    width: 80%;
    margin-top: 0.5rem;
}

section.demo_div .demo_video {
    border-radius: 30px;
    background: linear-gradient(to bottom, #E3A9EF, #C0CEF3);
    text-align: center;
    padding: 3rem 2rem;
    margin: 3rem 0;
    z-index: 10;
}

section.demo_div .demo_div_container::after {
    content: url('../Assests/images/rounds.png');
    position: absolute;
    right: -12%;
    top: 290%;
}

section.demo_div .demo_div_container::before {
    content: '';
    background-image: url('../Assests/images/rounds.png');
    background-size: cover;
    width: 300px;
    height: 300px;
    position: absolute;
    left: -10%;
    top: -200%;
    z-index: 0;
}

section.demo_div .demo_video img {
    width: 100%;
}

.demo_video_container h2 {
    margin-bottom: 3rem;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1px;
}

@media screen and (max-width: 991px) {

    section.demo_div .demo_div_container::before,
    section.demo_div .demo_div_container::after,
    .home_container .home_head::after {
        display: none;
    }
}

/* ------------------------------------Demo Video Section Styling end--------------------------------------- */

/* ------------------------------------Chatbot details Section Styling--------------------------------------- */
section.chatbot_details {
    margin-top: 12rem;
}

.chatbot_details_container {
    background-image: url('../Assests/images/Robot-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    z-index: 10;
}

.chatbot_details_container .details_flex {
    background-color: #ffffff;
    padding: 4rem 1.5rem;
    position: relative;
    top: 0%;
    left: 12%;
    transform: scale(1.1);
    box-shadow: 0px 3px 6px #00000029;
}

.chatbot_details_container .details_flex .details_div_inside {
    padding-left: 1rem;
    border-left: 4px solid;
    border-image: linear-gradient(to bottom, #800080, #C8648B, #C8648B, #EF4100) 1 100%;
    margin: 5rem 0;
}

.chatbot_details_container .details_flex .details_div_inside h1 {
    font-weight: 700;
    margin-bottom: 0;
}

@media screen and (max-width: 768px) {
    .chatbot_details_container .details_flex {
        position: static;
        margin: 0 auto;
    }
}

/* ------------------------------------Chatbot details Section Styling--------------------------------------- */

/* ------------------------------------Ready Chatbot Section Styling--------------------------------------- */
section.ready_chatbot {
    margin-top: 10rem;
}

.ready_chatbot_container {
    text-align: center;
    flex-wrap: wrap;
}

.ready_chatbot_container .demo_headings {
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ready_chatbot_container .demo_headings .border_bottom_heading {
    width: 70%;
}

.ready_chatbot_cards .ready_chatbot_cards_container {
    margin: auto;
    margin-top: 4rem;
}

.ready_chatbot_cards .card {
    background: linear-gradient(to bottom, #800080, #FEB093) !important;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 auto;
    height: 300px;
    width: 300px;
    border: none;
    margin-bottom: 1.5rem;
}

.ready_chatbot_cards .card .card_contents {
    margin: 1rem 0;
}

.ready_chatbot_cards .card .card-img-top {
    width: 3.5rem;
    margin-bottom: 0.2rem;
}

.ready_chatbot_cards .card .card-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0.6rem;
}

.ready_chatbot_cards .card_column {
    margin: auto;
}

/* ------------------------------------Ready Chatbot Section Styling end--------------------------------------- */

/* ------------------------------------Get In Touch Section Styling--------------------------------------- */
section.get_in_touch {
    margin-top: 5rem;
}

.get_in_touch_container {
    background-image: url('../Assests/images/get_in_touch_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.get_in_touch_container .get_in_touch_text {
    width: fit-content;
    text-align: center;
    color: #ffffff;
}

.get_in_touch_container .get_in_touch_text h5,
.get_in_touch_container .get_in_touch_text h1 {
    margin-bottom: 1.5rem;
}

.get_in_touch_container .get_in_touch_btn_div {
    margin: 2rem 0 1rem 0;
}

.get_in_touch_container .get_in_touch_btn {
    background-color: #ffffff;
    color: #EF4100;
    font-weight: 500;
    font-size: 18px;
    padding: 0.5rem 2.5rem;
    border: none;
    border-radius: 20px;
}

/* ------------------------------------Get In Touch Section Styling end--------------------------------------- */

/* ------------------------------------FAQs Section Styling--------------------------------------- */
section.faqs {
    margin-top: 4rem;
}

.faqs_container {
    text-align: center;
}

.faqs_container h1 {
    margin-bottom: 1rem;
}

.faqs_container .faqs_div {
    margin: auto;
    padding: 1rem;
}

.faqs_container .faqs_div .accordion-item {
    margin: 1.5rem 0;
    border: none;
    background-color: #fef2fe;
    border-radius: 15px !important;
    box-shadow: 0 .3rem 0.5rem rgba(0, 0, 0, .15) !important;
}

.accordion-header {
    border-radius: 15px !important;
}

.show,
.accordion-button {
    border-radius: 15px !important;
    /* font-size: 20px !important; */
    font-weight: 600;
    /*background-color: #fef2fe !important;*/
}

.accordion-item .accordion-button {
    font-size: 20px !important; 
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.accordion-body {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    font-weight: 500;
    text-align: left !important;
}

.accordion-button:not(.collapsed) {
    background-color: #fef2fe !important;
    box-shadow: none;
}

.faqs_container .see_more_div {
    display: flex;
    align-items: center;
    justify-content:flex-end;;
}

.faqs_container .see_more_btn {
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.faqs_container .see_more_btn:active,
.faqs_container .see_more_btn:focus,
.faqs_container .see_more_btn:focus-visible {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    color: black !important;
    background-color: transparent !important;
}