.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 25px;
    margin-left: 1rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F58634;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #F58634 !important;
}

input:focus+.slider {
    box-shadow: 0 0 1px black;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Toggle button css above */

.notification_wrapper {
    margin: 2rem 0;
}

.notification_container {
    padding: 2rem;
    border: 2px solid #000000;
    border-radius: 20px;
}

.notification_container h5 {
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0 12px;
}

.notification_container .toggle_btn_div {
    margin: 2rem 0 1rem 0;
}

.notification_container .toggle_btn_div .nowrap_text1 {
    font-weight: 500;
    font-size: 16px;
}

.notification_container form .email_input {
    margin: 1rem 0 2rem 0;
}

.notification_container form .email_input input {
    width: 100%;
    height: 38px;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 500;
}

.checkbox_input_div {
    padding: 0 12px;
    margin-bottom: 2rem;
}

.checkbox_input_div label {
    white-space: nowrap;
    font-size: 14px;
}
/* --------------------------------------Notification Page Css-------------------------------------- */

/* --------------------------------------Delete Page Css-------------------------------------- */

.delete_page_container .delete_chatbot_btn {
    background-color: #EE2A2A;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding: 0.4rem 1rem;
    border: none;
    border-radius: 7px;
    width: 200px;
    margin: 2rem 0 5rem 0;
}

.delete_page_container .delete_chatbot_btn p {
    margin: 0;
    font-weight: 500;
}

.delete_page_container .delete_chatbot_btn img {
    width: 0.9rem;
}

.notifications_email_input_disabled {
    pointer-events: none;
    opacity: 0.5;
} 