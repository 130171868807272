.friends_chatbot_wrapper {
    margin: 3rem 0;
}

.friends_chatbot_wrapper .friends_chatbot_heading {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.friends_chatbot_wrapper .friends_chatbot_heading h2 {
    font-weight: 700;
}

.friends_chatbot_wrapper .friends_chatbot_heading p {
    font-size: 21px;
    color: #707070;
    font-weight: 500;
}

.friends_chatbot_container {
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #707070;
    padding: 2rem 1rem;
}

.friends_chatbot_container .form_head_text p {
    text-align: center;
    color: #707070;
    font-weight: 500;
    font-size: 15px;
    margin: 1rem 0 3rem 0;
}

.friends_chatbot_container .friends_input_field input {
    width: 100%;
    height: 40px;
    margin-bottom: 1.7rem;
    border: none;
    background-color: #FDF2FF;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 500;
}

.friends_chatbot_container .sign_up_btn_friends {
    margin: 2rem 0 3rem 0;
}

.friends_chatbot_container .sign_up_btn_friends .friends_signup {
    font-size: 18px;
    font-weight: 500;
    background-color: #FDF2FF;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
}
