.custom_qa_container h3 {
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
}

.custom_qa_container .custom_qa_head {
    display: flex;
    align-items: center;
}

.custom_qa_container .custom_qa_head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom_qa_container .custom_qa_btn {
    display: flex;
    align-items: center;
    background-color: #707070;
    color: #ffffff;
    gap: 0.6rem;
}

.custom_qa_container .custom_qa_btn p {
    margin: 0;
    font-weight: 500;
    white-space: nowrap;
}

.custom_qa_container .custom_qa_btn img {
    width: 1.2rem;
}

.custom_qa_container .custom_qa_text {
    margin: 3rem 0;
}

.custom_qa_container .custom_qa_text p {
    font-weight: 500;
}

.setting_container .sidebar_toggle_btn {
    background-color: #800080 !important;
    color: #ffffff !important;
    width: 70px;
    height: -moz-fit-content;
    height: fit-content;
    text-align: right;
    border: none;
    position: relative;
    left: -48px;
}

.custom_qa_container .add_custom_qa_container {
    background-color: #00000029;
    padding: 2rem;
    border-radius: 30px;
    margin: 3rem 0;
}

.custom_qa_container .add_custom_qa_container label {
    font-weight: 500;
    padding: 0 12px;
    margin-bottom: 0.7rem;
    font-size: 17px;
}

.add_custom_question,
.add_custom_answer {
    margin-bottom: 1.5rem;
}

.custom_qa_container .add_custom_qa_container textarea {
    width: 100%;
    border-radius: 12px;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 14px;
}

.added_queations_wrapper {
    height: 600px;
    overflow: auto;
    padding: 1rem;
    border-radius: 20px !important;
}

.added_queations_container .added_queations_div {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 20px;
    margin-bottom: 1rem;
}

.added_queations_container .added_queations_div span {
    font-size: 18px;
    font-weight: 700;
}

.added_queations_container .added_queations_div p {
    font-size: 17px;
    font-weight: 500;
}

.added_queations_container .delete_question {
    display: flex;
    justify-content:flex-end;;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    border-top: 1px solid #707070;
    padding-top: 1rem;
}

.added_queations_container .delete_question .remove_question_btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.added_queations_container .delete_question img {
    width: 1rem;
}

.added_queations_container .delete_question p {
    font-size: 17px;
    font-weight: 700;
    color: #ff0000;
    margin: 0;
}

@media screen and (max-width: 576px) {
    .custom_qa_container .sidebar_toggle_btn {
        width: 75px;
    }

    .custom_qa_container .custom_qa_head_container {
        display: block;
    }

    .custom_qa_container .custom_qa_btn_div {
        display: flex;
        justify-content:flex-end;;
        margin-top: 3rem;
    }

    .custom_qa_container h3 {
        font-size: 28px;
    }

    .custom_qa_container .custom_qa_btn p {
        font-size: 14px;
    }

    .custom_qa_container .custom_qa_btn img {
        width: 1rem;
    }

    .custom_qa_container .custom_qa_text {
        margin-top: 2rem;
    }

    .custom_qa_container .add_custom_qa_container,
    .added_queations_container .added_queations_div {
        padding: 2rem 1rem;
    }

}