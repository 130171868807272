.manage_subscritptions_wrapper {
    margin: 4rem 0 3rem 0;
}

.manage_subscritptions_container .api_div {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.7rem 1.5rem;
    margin: 2rem 0;
}

.manage_subscritptions_container .api_div .api_key {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.manage_subscritptions_container .api_div .api_text {
    margin: 0;
    font-weight: 500;
    color: #707070;
}

.manage_subscritptions_container .active_plan_details {
    background-color: #FFFFFF;
    border:1px solid #707070;
    border-radius: 8px;
    padding: 2rem 1rem;
}
.manage_subscritptions_container .active_plan_details .plan_detalis_list li {
    font-weight: 500;
    margin-bottom: 0.8rem;
}

.manage_subscritptions_container .active_plan_details .plan_details {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #707070;
}

.manage_subscritptions_container .active_plan_details .plan_upgrade {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.manage_subscritptions_container .active_plan_details .plan_upgrade p {
    margin-bottom: 1rem;
    font-size: 17px;
    font-weight: 500;
}

.manage_subscritptions_container .active_plan_details .plan_upgrade .upgrade_now_btn_div button {
    background-color: #F58634;
    border: none;
    color: #000;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
}

.manage_subscritptions_container .billing_div {
    background-color: #FFFFFF;
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 2rem;
    margin: 2rem 0;
}

.manage_subscritptions_container .billing_div h5 {
    font-weight: 600;
}

.manage_subscritptions_container .billing_div p {
    color: #707070;
    font-weight: 500;
    margin-top: 1rem;
}