.sidebar {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #707070;
    width: 260px;
    transition: all 0.4s ease-in-out;
}

.sidebar_head {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #800080;
    padding: 2rem 1rem;
    border-radius: 10px;
    border-bottom: 1px solid #707070;
}

.sidebar_head .head_text_div h4,
.sidebar_head .head_text_div p {
    margin: 0;
    color: #ffffff;
    white-space: nowrap;
}

.sidebar_head .head_img_div {
    width: 50px;
    height: 50px;
}

.sidebar_head .head_img_div img {
    width: 100%;
}

.sidebar_body_container ul {
    list-style: none;
    padding: 0 0.5rem 1rem 0.5rem;
    margin-top: 2rem;
}

.sidebar_body_container ul a {
    text-decoration: none;
    color: #000;
}

.sidebar_body_container .list_item {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sidebar_body_container ul li {
    margin: 2rem 0;
}

.sidebar_body_container .list_item p {
    margin: 0;
    font-weight: 500;
}

.sidebar_body_container .list_item img {
    width: 28px;
    margin-left: 0.5rem;
}

.sidebar_body_container .active {
    background-color: #F58634;
    border-radius: 20px;
    padding: 0.2rem 0.5rem;
}

.sidebar_expand {
    margin-left: 0 !important;
}

@media screen and (max-width: 991px) {
    .sidebar {
        width: 250px;
        margin-left: -300px;
        margin-right: 10px;
    }
}