.admin_dashboard_container {
    display: flex;
    gap: 1.5rem;
    margin-right: 1rem;
}

.admin_dashboard_container .admin_dashboard_head {
    display: flex;
    align-items: center;
    /* position: relative; */
}

.admin_dashboard_container .admin_dashboard_head .sidebar_toggle_btn {
    background-color: #FDF2FF;
    /* background-color: #000; */
    width: 100px;
    text-align: end;
    position: relative;
    left: -40px;
}

.admin_dashboard_container .admin_duration_tabs {
    display: flex;
    align-items: center;
    gap: 2rem;
    background-color: #F58634;
    height: 60px;
    padding: 0 2rem;
    border-radius: 12px;
    width: 100%;
}

.admin_dashboard_container .admin_duration_tabs p {
    margin: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 17px;
}

.active_tab1 {
    color: black !important;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 0.2rem 1rem;
}

.admin_dashboard_container .admin_duration_tabs div {
    cursor: pointer;
}

.admin_dashboard_container .dashboard_cards_row {
    margin: 2rem 0 1rem 0;
}

.admin_dashboard_container .dashboard_cards_row .card {
    padding: 1rem;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    height: webkit-fill-available !important;
    margin: 1rem;
}

.admin_dashboard_container .dashboard_cards_row .card img {
    width: 2.3rem;
    height: 2.5rem;
}

.admin_dashboard_container .dashboard_cards_row .card .card-body {
    padding: 0;
    margin-top: 0.8rem;
}

.admin_dashboard_container .dashboard_cards_row .card .card-body p {
    margin-bottom: 0;
    font-weight: 500;
    color: #434343;
}

.admin_dashboard_container .dashboard_cards_row .card .card-body h4 {
    font-weight: 700;
    margin: 0;
}

.graphs_admin_dashboard {
    height: 450px;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 1rem;
    background-color: #FFFFFF;
}

.graphs_admin_dashboard canvas{
    width: 100% !important;
}

@media screen and (max-width: 1410px) {
    .admin_dashboard_container .dashboard_cards_row {
        overflow-x: auto;
    }
    .admin_dashboard_container .dashboard_cards_row .card .card-body h4 {
        font-size: 21px;
    }
    .admin_dashboard_container .dashboard_cards_row .card {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .admin_dashboard_container .dashboard_cards_row .card .card-body p {
        font-size: 14px;
    }
  }

  @media screen and (max-width: 800px) {
    .admin_dashboard_container .admin_dashboard_head .sidebar_toggle_btn {
        background-color: #FDF2FF;
        /* background-color: #000; */
        width: 80px;
        text-align: end;
        position: relative;
        left: -40px;
    }
  }

  @media screen and (max-width: 768px) {
    .admin_dashboard_container .dashboard_cards_row .card {
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        flex-direction: row;
        gap: 1rem;
    }
  }

  @media screen and (max-width: 500px) {
    .admin_dashboard_container .admin_dashboard_head {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .admin_dashboard_container .admin_duration_tabs {
        gap: 0.8rem;
        justify-content: space-between;
    }
  }