.create_options {
    background-color: #EEF1F3;
}

.create_options_header {
    z-index: 1;
}

.create_options_container h1 {
    margin-left: 2rem;
}

.create_options_wrapper {
    height: 80vh;
    margin-top: 4rem;
}

.options_wrapper {
    margin-top: 1.5rem;
    border: 1px solid #707070;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.options_wrapper .options_heading {
    margin: 4rem 0 3rem 0;
    text-align: center;
    padding: 0 1rem;
}

.options_container {
    width: 100%;
    margin-bottom: 6rem;
}

.options_container a {
    text-decoration: none;
}

.options_container a:hover {
    color: #FFFFFF;
}

.create_option {
    margin: auto;
    background-color: #707070;
    border-radius: 12px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 4rem 1rem;
    cursor: pointer;
}

.option_icon {
    background-image: url('../Assests/images/website_icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.option_icon img {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .create_option {
        flex-direction: column;
        padding: 3rem 1rem;
        gap: 0.5rem;
    }

    .create_options_wrapper {
        height: 86vh;
    }

    .create_option h2 {
        margin: 0;
    }
}

@media screen and (max-width: 576px) {
    .create_option {
        flex-direction: row;
        margin-bottom: 1rem;
    }

    .options_container {
        margin-bottom: 3rem;
    }

    .create_options_wrapper {
        height: 83vh;
    }

    .create_option {
        padding: 2rem 1rem;
    }

    .create_options_footer_div {
        margin-top: 8rem;
    }
}