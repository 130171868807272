.update_knowledge_container .update_knowledge_tabs_container {
    width: 100%;
    margin: 2rem 0 0.2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.data_source_btn_div button {
    font-size: 14px;
    padding: 0.2rem 0.8rem;
    height: 40px;
}

.update_knowledge_container .update_knowledge_tabs {
    display: flex;
    gap: 2rem;
}

.update_knowledge_container .update_knowledge_tabs .icon_text_div h5 {
    font-size: 20px;
    font-weight: 700;
}

.update_knowledge_container .update_knowledge_tabs .icon_text_div img {
    width: 2.3rem;
}

.update_knowledge_container .update_tabs_pages {
    margin-top: 2rem;
}

.update_knowledge_container .update_tabs_pages .file_input .input_body_style {
    border: 2px solid #707070 !important;
    border-radius: 20px;
    overflow: hidden;
}

.update_knowledge_container .update_tabs_pages .file_input .input_body_style .no_file_text {
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.update_knowledge_container .update_tabs_pages .file_input .input_body_style .no_file_text p {
    font-size: 22px;
    font-weight: 500;
    color: #707070;
}

.input_body_style {
    border-color: #707070 !important;
}

.update_knowledge_container .update_tabs_pages .website_input .input_body_style {
    border: none !important;
}

.update_knowledge_container .update_tabs_pages .website_input .website_container {
    min-height: auto !important;
}

.update_knowledge_btn {
    display: flex;
    align-items: center;
    background-color: #707070;
    color: #FFFFFF;
    font-weight: 500;
    gap: 10px;
    padding: 0.7rem 1.5rem;
}

.update_knowledge_btn:hover,
.update_knowledge_btn:active {
    background-color: #707070;
    color: #FFFFFF;
}

.update_knowledge_btn p {
    margin: 0;
}

.update_knowledge_btn img {
    width: 1.2rem;
}

.pages_characters_counter p {
    font-size: 17px;
    font-weight: 700;
    padding-left: 2rem;

}

@media screen and (max-width: 991px) {
    .update_knowledge_container .update_tabs_pages .create_chatbot_footer .delet_build_btns {
        flex-direction: row !important;
    }
}

@media screen and (max-width: 576px) {
    .update_knowledge_container .update_tabs_pages .create_chatbot_footer .delet_build_btns {
        flex-direction: column !important;
        gap: 1.5rem;
    }

    .update_knowledge_container .update_tabs_pages .remove_data_source {
        justify-content: center;
    }

    .update_knowledge_container .update_tabs_pages .text_counter_div {
        justify-content: center !important;
    }
}

.tabel_footer_update_knowledge {
    background-color: transparent !important;
}

/* Loader */

.loader-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.show {
    display: flex;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #800080;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}