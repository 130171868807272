.conversation_container .date_filter {
    border: 1px solid #000;
    width: 280px;
    border-radius: 7px;
    position: relative;
}

.conversation_container .no_conversation_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.conversation_container .date_filter input {
    font-weight: 500;
    height: 38px;
    font-size: 15px;
}

.conversation_container .calender_icon {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.chat_history_container {
    margin: 2rem 0;
}

.selected_chat_container .selected_chat_history,
.chat_history_list_container .chat_history_list {
    border: 1px solid #707070;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 1.5rem 0.5rem;
    height: 465px;
    /* margin-bottom: 1rem; */
}

.chat_history_list_container .chat_list {
    border: 1px solid #707070;
    border-radius: 20px;
    padding: 1rem;
    background-color: #EEF1F3;
    margin-bottom: 0.8rem;
    cursor: pointer;
}

.chat_history_list_container .chat_list p {
    margin: 0;
    margin-bottom: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat_history_list_container .chat_list .chat_list_body p {
    width: 95%;
    margin: 0;
    margin-bottom: 0.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat_history_list_container .chat_list h6 span {
    font-weight: 400;
    font-size: 14px;
}

.chat_history_list_container .chat_list .time_text {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
}

.chat_history_list_container .chat_list p {
    font-weight: 500;
}

.chat_history_list_container .chat_list_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selected_chat_container .selected_chat_history .no_chat_selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.selected_chat_container .selected_chat_history .no_chat_selected img {
    width: 3rem;
}

.selected_chat_history .received_msg,
.selected_chat_history .sent_msg {
    width: fit-content;
}

.selected_chat_history .msg_div {
    display: flex;
    flex-direction: column;
}

.selected_chat_history .received_msg {
    align-self: flex-end;
}

.selected_chat_history .sent_msg {
    align-self: flex-end;
    background-color: #800080;
    color: #ffffff;
}

.selected_chat_container .converssation_recived_msg {
    /* align-self: flex-end !important; */
    margin-right: 0 !important;
    display: flex;
    flex-direction: column;
}

.converssation_sent_msg {
    align-self: flex-start !important;
}

.chat_history_selected {
    border: 3px solid #800080 !important;
}

.selected_chat_container .selected_chat_history,
.chat_history_list_container .chat_history_list {
    overflow: auto;
}

.selected_chat_container .selected_chat_history::-webkit-scrollbar,
.chat_history_list_container .chat_history_list::-webkit-scrollbar {
    /* display: none; */
    width: 3px;
}

@media screen and (max-width: 576px) {
    .conversation_container .date_filter_div {
        display: flex;
        justify-content: flex-end;
        ;
        margin-top: 3rem;
    }
}

.daterangepicker .calendar-time {
    display: none !important;
}

@media screen and (min-width: 1250px) {
    .selected_chat_container .selected_chat_history .received_msg {
        max-width: 88% !important;
    }

    .selected_chat_container .selected_chat_history .sent_msg {
        max-width: 88% !important;
    }
}

@media screen and (max-width: 1250px) {
    .selected_chat_container .selected_chat_history .received_msg {
        max-width: 96% !important;
    }

    .selected_chat_container .selected_chat_history .sent_msg {
        max-width: 96% !important;
    }
}

.conversation_container.setting_container {
    gap: 1rem !important;
}