.profile {
    background-color: #EEF1F3;
}

.profile_container {
    margin-top: 4rem;
}

.profile_form_container {
    background-color: #FFFFFF;
    padding: 2rem 1.5rem;
    border-radius: 20px;
}

.profile_container h1 {
    margin-left: 1.5rem;
    margin-bottom: 2rem;
}

.profile_form_container .input_fields input {
    width: 100%;
    height: 42px;
    margin-bottom: 1.3rem;
    padding: 0.3rem 1rem;
    background-color: #FDF2FF;
    color: #000;
    font-weight: 400;
    border: none;
    border-radius: 7px;
}

.profile_form_container .input_fields .input_div_signin input {
    margin-bottom: 0 !important;
}

.profile_form_container .input_fields .name {
    display: flex;
    gap: 16px;
}

.profile_form_container .password_input {
    position: relative;
}

.profile_form_container .password_input .password_toggle_icon {
    position: absolute;
    top: 45px;
    right: 16px;
}

.profile_form_container .input_fields label {
    font-size: 19px;
    margin-left: 1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.profile_form_container .update_btn {
    background: linear-gradient(to right, #800080, #FC9E4C);
    color: #FFFFFF;
    font-weight: 500;
    background-color: transparent;
    width: 100px;
    height: 40px;
    border: none;
    margin-top: 1.3rem;
}

@media screen and (max-width: 576px) {
    .profile_wrapper {
        padding: 0;
    }
}