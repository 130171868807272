.create_chatbot_footer_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.plain_text{
    color: red !important;
}

.txtclass{
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    color:#000;
}

.create_chatbot_footer_container .left_div {
    color: red;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.create_chatbot_footer_container .left_div .total_text {
    color: #000;
    font-weight: 700;
    font-size: 20px;
}

.create_chatbot_footer_container .right_div {
    width: 50%;
}

.create_chatbot_footer_container .right_div .chatbot_role {
    color: #000;
    font-weight: 700;
    font-size: 20px;
    text-align: end;
}

.role_btns {
    display: flex;
    justify-content:flex-end;;
    gap: 15px;
}

.role_btns .customer_service_btn {
    border: 1px solid black;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.role_btns .answer_data_btn {
    border: 1px solid black;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: auto;
}

.role_btns .answer_data_btn div,
.role_btns .customer_service_btn div {
    white-space: nowrap;
    margin-left: 0.5rem;
}

.selected_btn {
    border: 2px solid #F58634 !important;
}

.delet_build_btns {
    display: flex;
    align-items: flex-end;;
    justify-content: space-between;
}

.delet_build_btns .remove_btn {
    color: red;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    white-space: nowrap;
    gap: 10px;
}

.delet_build_btns .remove_btn p {
    margin: 0;
}

.delet_build_btns .build_btn {
    background-color: #800080;
    border: 1px solid #707070;
    width: 50%;
}

.delet_build_btns .build_btn p {
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
}

@media screen and (max-width: 1200px) {

    .role_btns .customer_service_btn,
    .role_btns .answer_data_btn {
        font-size: 1rem;
    }

    .role_btns .customer_service_btn img,
    .role_btns .answer_data_btn img {
        width: 20px;
        height: 20px;
    }

    .delet_build_btns .build_btn {
        width: 55%;
    }
}

@media screen and (max-width: 991px) {
    .delet_build_btns {
        flex-direction: column-reverse;
        align-items: center;
        gap: 0.7rem;
    }
}

@media screen and (max-width: 768px) {
    .create_chatbot_footer_container {
        flex-direction: column;
    }

    .text_counter_div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .create_chatbot_footer_container .right_div {
        width: 100%;
    }

    .create_chatbot_footer_container .right_div .chatbot_role {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
    }

    .create_chatbot_footer_container .right_div .chatbot_role p {
        margin: 0;
        font-size: 1.1rem;
        white-space: nowrap;
    }

    .role_btns {
        justify-content: center;
    }

    .role_btns {
        gap: 10px;
    }
}

@media screen and (max-width: 680px) {
    .create_chatbot_footer_container .right_div .chatbot_role {
        display: block;
    }

    .create_chatbot_footer_container .right_div .chatbot_role p {
        text-align: left;
    }

    .role_btns {
        justify-content: flex-start;
        margin-top: 0.3rem;
    }
}

@media screen and (max-width: 545px) {
    .create_by_text_continer {
        margin: 0;
        padding: 0;
    }

    .create_by_text_continer h1,
    .name_btn_div_wrapper {
        padding: 0 1rem;
    }

    .create_by_text_continer .textarea_container {
        padding: 1rem 1rem;
    }

    .delet_build_btns .build_btn {
        width: 90%;
    }

    .role_btns {
        gap: 0.5rem;
    }

    .create_chatbot_footer_container .right_div .chatbot_role p,
    .create_chatbot_footer_container .left_div .plain_text,
    .create_chatbot_footer_container .left_div .total_text,
    .delet_build_btns .remove_btn p {
        font-size: 1rem;
    }

    .delet_build_btns .build_btn p {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .role_btns .customer_service_btn,
    .role_btns .answer_data_btn {
        font-size: 0.7rem;
    }

    .role_btns .customer_service_btn img,
    .role_btns .answer_data_btn img {
        width: 18px;
        height: 18px;
    }

    .role_btns .answer_data_btn div,
    .role_btns .customer_service_btn div {
        margin-left: 0.3rem;
    }
}

.delet_build_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total_char_div p {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
}