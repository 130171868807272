.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 25px;
    margin-left: 1rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F58634;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #F58634 !important;
}

input:focus+.slider {
    box-shadow: 0 0 1px black;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Toggle button css above */

.add_to_website_wrapper {
    margin: 2rem 0;
}

.add_to_website_container {
    /* background-color: #00000029; */
    border: 2px solid #000;
    padding: 2rem;
    border-radius: 20px;
}

.add_to_website_container .nowrap_text1 {
    font-size: 18px;
    font-weight: 700;
}

.add_to_website_container form label {
    font-weight: 500;
    padding: 0 12px;
    margin-bottom: 1rem;
}

.add_to_website_container form textarea {
    width: 100%;
    border-radius: 12px;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 14px;
}

.add_to_website_container form .input_div_website {
    margin: 2rem 0;
}

.add_to_website_container form .update_btn_div {
    display: flex;
    justify-content: flex-end;
    ;
}

.add_to_website_container form .update_btn_div .update_btn {
    background-color: #F58634;
    color: #000;
    font-weight: 700;
    width: 150px;
    height: 45px;
    margin: 0;
    border: none;
}

.add_to_website_container form .update_btn_div .update_btn:active {
    background-color: #F58634 !important;
    color: #000 !important;
    font-weight: 700 !important;
    width: 150px !important;
    height: 45px !important;
    margin: 0 !important;
}

.input_textareas_website_disabled {
    opacity: 0.5;
    pointer-events: none;
}