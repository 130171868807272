.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 25px;
    margin-left: 1rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc !important;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #F58634;
}

input:focus+.slider {
    box-shadow: 0 0 1px black;
}

input:checked+.slider:before {
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.basic_setting_container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 20px;
    margin: 2rem 0;
}

.basic_setting_container .input_div {
    margin: 2rem 0;
}

.basic_setting_container .input_div label {
    margin: 0;
    margin-right: 0.5rem;
}

.basic_setting_container .label_style {
    font-size: 17px;
    font-weight: 700;
}

.basic_setting_container .input_div1 label {
    font-size: 17px;
    font-weight: 700;
}

.nowrap_text {
    white-space: nowrap;
}

.nowrap_text1 {
    white-space: nowrap;
    padding: 0 12px;
}


.text_below_toggle {
    padding: 0 12px;
    margin-top: 5px;
}

.textarea_basic_setting {
    margin-top: 5px;
    padding: 0;
}

.textarea_basic_setting textarea {
    border: 2px solid #707070;
    border-radius: 10px;
    box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, .15) !important;
    padding: 0.5rem;
    font-weight: 500;
}

.radio_lable {
    width: fit-content !important;
    margin: 0 1rem 0 0.3rem !important;
    font-weight: 500;
}

.input_box_basic {
    height: 35px;
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 0.2rem 1rem;
    font-size: 16px;
    box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, .15) !important;
    font-weight: 500;
}

.basic_setting_container .input_div select {
    height: 35px;
    border: 1px solid #707070;
    border-radius: 8px;
    padding: 0.2rem 1rem;
    font-size: 16px;
    box-shadow: 0.2rem 0.4rem 0.4rem rgba(0, 0, 0, .15) !important;
}

.basic_setting_container .input_div .chatbot_id_number {
    margin: 0;
    font-weight: 600;
}

.setting_container .update_btn_div {
    display: flex;
    justify-content:flex-end;;
}

.setting_container .update_btn_div .update_btn {
    background-color: #F58634;
    color: #000;
    font-weight: 700;
    width: 150px;
    height: 45px;
    margin: 1rem 0;
    border: none;
}

.setting_container form .update_btn_div .update_btn:active {
    background-color: #F58634 !important;
    color: #000 !important;
    font-weight: 700 !important; 
    width: 150px !important; 
    height: 45px !important;
    margin: 0 !important;
}

@media screen and (max-width: 991px) {
    .basic_setting_container .input_div label {
        margin-right: 0;
    }

}

@media screen and (max-width: 640px) {
    .chatbot_id_number_span {
        padding: 0;
    }

    .chatbot_id_number_span p {
        white-space: nowrap;
    }

    .basic_setting_container {
        padding: 1.5rem 1rem;
    }

    /* .setting_container .update_btn_div {
        display: flex;
        justify-content: center;
    } */
}

@media screen and (max-width: 576px) {
    .chatbot_id_number_span {
        padding:0 12px;
    }

    .basic_setting_container .input_div label {
        margin-bottom: 0.5rem;
    }

    .textarea_basic_setting {
        margin-left: 0;
        padding: 0;
    }

    .setting_container .tab_border,
    .setting_container .setting_tabs {
        width: 100%;
    }

    .setting_container .setting_tabs p {
        font-size: 14px;
    }
}

.radio_btns_input input,
.radio_lable {
    cursor: pointer;
}

.radio_lable_disabled {
    opacity: 0.5;
    pointer-events: none;
    /* cursor: not-allowed; */
}

.temp_slider .slider_label_style {
    margin-bottom: 0.5rem !important;
}

.temp_slider div {
   padding: 0 12px;
}

.temp_slider .MuiSlider-root {
    color: #F58634 !important;
}