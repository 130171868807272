body {
    background-color: #EEF1F3;
}

.forgot_password_container {
    border-radius: 50px;
    margin-top: 6rem !important;
    background-color: #FFFFFF;
}

.forgot_password_container .left_section {
    background: linear-gradient(to bottom, #800080, #FC9E4C);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.forgot_password_container .forgot_img_div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
    width: 250px;
    height: 300px;
}

.forgot_password_container .forgot_img_div img {
    width: 100%;
}

.forgot_password_container .right_section {
    padding: 2rem 0;
}

.forgot_password_container .right_section h2 {
    font-weight: 700;
    font-size: 32px;
    margin: 1rem 0;
    margin-bottom: 0.5rem;
}

.forgot_password_container .right_section p {
    font-size: 17px;
    text-align: center;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 23px;
}

.forgot_password_container .right_section .err_msg {
    color: red;
    font-size: 15px;
    margin: 0;
}

.forgot_password_container .right_section .input_fields label {
    font-size: 16px;
    font-weight: 500;
    margin-left: 1rem;
    margin-bottom: 0.2rem;
    margin-top: 0.5rem;
}

.forgot_password_container .right_section .input_fields input {
    width: 100%;
    height: 40px;
    margin-bottom: 1rem;
    padding: 0.3rem 1rem;
    background-color: #FDF2FF;
    color: #000;
    font-weight: 400;
    border: none;
    border-radius: 7px;
}

.forgot_password_container .right_section .input_fields .verify_input {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.forgot_password_container .right_section .input_fields .verify_input input::-webkit-outer-spin-button,
.forgot_password_container .right_section .input_fields .verify_input input::-webkit-inner-spin-button {
    display: none;
}

.forgot_password_container .right_section .input_fields .verify_input input {
    width: 50px;
    height: 50px;
    font-weight: 500;
    text-align: center;
}

.forgot_password_container .right_section .otp_btn {
    background-color: #F9F9F9;
    border: 2px solid #000;
    color: #363636;
    font-weight: 700;
    padding: 0.5rem 0;
    width: 150px;
    margin: 1rem 0;
    margin-bottom: 1.5rem;
}

.go_to_login {
    background-color: #F9F9F9;
    border: 2px solid #000;
    color: #363636;
    font-weight: 700;
    padding: 0.5rem 0;
    width: 130px;
    transition: all 0.4s ease-in-out;
}

.go_to_login:hover {
    background: linear-gradient(to right, #800080, #FC9E4C);
    border: none;
    color: #FFFFFF;
    font-weight: 700;
    padding: 0.5rem 0;
    width: 130px;
}

.verify_modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify_modal p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    margin-left: 0.3rem;
}

.verify_modal img {
    width: 40px;
    height: 40px;
}

.close_btn {
    background: linear-gradient(to right, #800080, #FC9E4C);
    border: none;
}

.modal_body p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-top: 0.2rem;
    letter-spacing: 1px;
}

.modal_body img {
    width: 70px;
    height: 70px;
}

.modal-content {
    border-radius: 20px !important;
}

.forgot_password_container .right_section .back_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgot_password_container .right_section .back_btn .resend_otp_btn {
    color: #800080;
    font-weight: 500;
    text-decoration: 2px underline #FC9E4C;
    cursor: pointer;
}

.forgot_password_container .right_section .back_btn .timer {
    color: #FC9E4C;
}

.forgot_password_container .right_section .back_btn .timer span {
    color: #800080;
    pointer-events: none;
}

.forgot_password_container .right_section .back_btn a {
    text-decoration: none;
}

.forgot_password_container .right_section .back_btn a .goback_text {
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
    color: #800080;
    margin-left: 0.2rem;
}

.forgot_password_container .right_section .password_input {
    position: relative;
}

.forgot_password_container .right_section .password_input .password_icon {
    position: absolute;
    top: 12px;
    right: 13px;
}

@media screen and (max-width: 768px) {

    .forgot_password_container .left_section {
        display: none;
    }

    .forgot_password_container .left_section {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 0;
    }

    .forgot_password_container {
        border-radius: 30px;
        margin-top: 4rem !important;
    }

    .forgot_password_container .sigin_img_div img {
        width: 70%;
    }
}

@media screen and (max-width: 576px) {
    .forgot_password_container {
        box-shadow: none !important;
        margin-top: 3rem !important;
    }
}