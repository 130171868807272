.footer {
  margin-top: 6rem;
}

.footer_wrapper {
  background-image: url("../Assests/images/footer_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 4rem 1rem 2rem 1rem;
}

.footer_container .left_section .brand_logo {
  width: 170px;
  margin-bottom: 1.5rem;
}

.footer_container .left_section .brand_logo img {
  width: 100%;
}

.footer_container .left_section .footer_text {
  margin-bottom: 2rem;
}

.footer_container .left_section .footer_text p {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
}

.footer_container .social_media {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
}

.footer_container .social_media img {
  width: 2.2rem;
}

.footer_container .right_section {
  display: flex;
  justify-content: space-around;
  color: #ffffff;
}

.footer_container .right_section ul {
  list-style: none;
  padding: 0;
}

.footer_container .right_section ul li {
  margin-bottom: 0.7rem;
}

/* .footer_container .right_section ul li span {
    white-space: nowrap;
} */

.footer_container .right_section h5 {
  margin-bottom: 1.5rem;
}

.footer_container .right_section ul li img {
  width: 1.2rem;
  /* margin-right: 0.5rem; */
  padding-top: 0.3rem;
}

.free_signup_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  color: #ffffff;
  font-weight: 500;
  background-color: #ffffff;
  /* max-width: fit-content; */
  margin: auto;
  padding: 0.3rem;
  border-radius: 30px;
}

.free_signup_btn:active,
.free_signup_btn:hover {
  color: #ffffff;
  background-color: #ffffff;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #ffffff !important;
  background-color: #ffffff !important;
}

.free_signup_btn p {
  color: #707070;
  font-size: 20px;
  margin: 0 2rem;
  white-space: nowrap;
}

.free_signup_btn .arrow_div {
  background-color: #f58634;
  border-radius: 20px;
}

.copyright_div {
  align-items: center;
  margin-top: 2rem;
}

.copyright_div a {
  text-decoration: none;
}

.copyright_div .copyright_text p {
  color: #ffffff;
}

a,
.arrow_div {
  cursor: pointer;
}

.right_section .privacy li a:hover,
.right_section .contact_info li a:hover {
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .copyright_div {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 1rem;
  }

  .copyright_div .copyright_text {
    text-align: center;
  }

  .footer_row_gap {
    gap: 2rem;
  }

  .footer_wrapper {
    background-image: url("../Assests/images/footer_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 1rem 2rem 1rem;
  }
}

@media screen and (max-width: 576px) {
  .marginbottomAfter576 {
    margin-bottom: 2rem !important;
  }
}

@media screen and (max-width: 400px) {
  .footer_container .right_section {
    flex-direction: column-reverse;
  }

  .footer_container .right_section ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .footer_container .right_section ul li {
    margin-bottom: 0.2rem;
  }
}

.contactFlex {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
