/* ------------------------------CREATE BY TEXT PAGE STYLING CSS START----------------------------- */
.crawl_stopper {
    display: flex;
    gap: 0.5rem;
}

.create_by_text {
    background-color: #EEF1F3;
    height: auto;
    padding-bottom: 4rem;
}

.crawl_stop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #800080;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    width: 150px;
    height: 44px;
    padding-bottom: 0.5rem;
}

.crawl_stop:hover {
    background-color: #800080;
    color: #FFFFFF;
}

.create_by_text_wrapper {
    margin-top: 4rem;
}

.create_options_header {
    z-index: 1;
}

.name_btn_div_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ;
    margin-top: 1.5rem;
}

.bot_name {
    width: 35%;
}

.bot_name input::placeholder {
    color: red;
}

.bot_name label {
    color: #000;
    font-weight: 500;
    margin-left: 0.5rem;
}

.bot_name input {
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
}

.add_more_btn {
    background-color: #707070 !important;
    border-radius: 8px;
    height: 50px;
    color: #FFFFFF !important;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.tips_more_btn .add_more_btn:hover,
.tips_more_btn .add_more_btn:active {
    font-weight: 500;
    background-color: #707070 !important;
    color: #FFFFFF;
}

.dropdown_div .dropdown-toggle::after {
    display: none !important;
}

.dropdown_div .dropdown-menu {
    background-color: #FDF2FF;
    left: -45px !important;
    margin-top: 0.5rem;
    transition: none !important;
}

@media screen and (max-width: 991px) {
    .dropdown_div .dropdown-menu {
        left: -5px !important;
    }
}

.dropdown_div .dropdown-menu p {
    margin: 0;
    font-size: 16px;
}

.dropdown_div .dropdown-menu .dropdown-item {
    cursor: pointer;
}

.dropdown_div .dropdown-menu .dropdown-item img {
    width: 50px !important;
}

.tips_div {
    margin-bottom: 1rem;
}

.tips_div .question_mark {
    background: linear-gradient(to bottom, #800080, #FC9E4C);
    padding: 0.1rem 0.5rem;
    font-size: 14px;
    border-radius: 50%;
    color: #FFFFFF;
    margin-right: 0.3rem;
}

.tips_div .tips {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.textarea_head {
    padding: 0 1rem;
}

@media screen and (max-width: 768px) {
    .textarea_head {
        flex-direction: column;
    }
}

.icon_text_div img {
    width: 52px;
    margin-bottom: 0.2rem;
}

.icon_text_div {
    cursor: pointer;
    padding-right: 0.5rem;
}

.selected_tab {
    border-bottom: 2px solid #F58634 !important;
}

.active {
    border-bottom: 1px solid #800080;
}

.textarea_head .data_source {
    font-weight: 700;
}

.textarea_head .character {
    font-weight: 400;
}

.icon_text_div h4 {
    margin: 0;
    color: #800080;
    margin-left: 0.5rem;
    font-weight: 700 !important;
    margin-bottom: 0.2rem;
    user-select: none;
}

.text_input textarea {
    width: 100%;
    min-height: 500px;
    font-size: 20px;
    padding: 1rem;
    font-weight: 500;
    border: 2px solid #800080;
    border-radius: 20px;
}

.textarea_container {
    background-color: #FFFFFF;
    padding: 1.5rem 2rem;
    border-radius: 20px;
    margin-bottom: 4rem;
}

@media screen and (max-width: 576px) {
    .name_btn_div_wrapper {
        display: block;
    }

    .name_btn_div_wrapper .add_more_btn {
        height: 40px;
    }

    .tips_more_btn {
        display: flex;
        justify-content: space-between;
    }

    .bot_name {
        width: 100%;
        margin-bottom: 0.6rem;
    }

    .bot_name input {
        height: 45px;
    }

    .name_btn_div {
        margin-bottom: 0.5rem;
    }
}

/* ------------------------------CREATE BY TEXT PAGE STYLING CSS END----------------------------- */


/* ------------------------------CREATE BY FILE PAGE STYLING CSS START----------------------------- */

.textarea_head .add_file_btn {
    background-color: #F58634;
    color: #FFFFFF;
    font-weight: 500;
    width: 150px;
    margin-bottom: 0.5rem;
}

.textarea_head .add_file_btn {
    cursor: pointer;
}

.textarea_container .file_table_div {
    min-height: 300px;
    border: 2px solid #800080;
    border-radius: 20px;
    overflow: hidden;
}

.textarea_container .file_table_div .no_file_text {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    color: #800080;
}

.file_table,
.file_table table {
    width: 100% !important;
    max-height: 500px !important;
    overflow: auto !important;
}

.file_table,
.file_table table::-webkit-scrollbar {
    /* display: none;*/
}

.file_table table thead {
    background-color: #707070 !important;
    color: #FFFFFF;
    width: 100% !important;
}

.textarea_container th {
    background-color: #707070 !important;
    color: #FFFFFF !important;
}

.textarea_container td {
    background-color: #FDF2FF !important;
    border-bottom: 5px solid white !important;
}

.file_table table tr {
    height: 55px;
}

.file_table table tr th {
    font-weight: 500;
}

.file_table table tbody tr {
    background-color: #FDF2FF !important;
    border-bottom: 5px solid white !important;
}

.file_table table tr th,
.file_table table tr td {
    padding: 0.5rem 1rem;
}

.processed {
    background-color: #63A813;
    color: #FFFFFF;
    padding: 0.1rem 0.6rem;
    height: 25px;
    width: fit-content;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
}


.notprocessed {
    background-color: #a8131f;
    color: #FFFFFF;
    padding: 0.1rem 0.6rem;
    height: 25px;
    width: fit-content;
    text-align: center;
    border-radius: 8px;
    font-size: 14px;
}

/* ------------------------------CREATE BY FILE PAGE STYLING CSS END----------------------------- */

/* ------------------------------CREATE BY WEBSITE PAGE STYLING CSS START----------------------------- */
.website_container {
    border: 2px solid #800080;
    border-radius: 20px;
    min-height: 400px;
    padding: 1.5rem;
}

.website_container .url_label span {
    color: red;
}

.website_container .url_label {
    font-weight: 700;
    margin-top: 2rem;
}

.website_container .url_input {
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
}

.website_container .url_input input {
    height: 45px;
    border: none;
    background-color: #FDF2FF;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    width: 100%;
}


.crawl_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #800080;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    width: 150px;
    height: 44px;
    padding-bottom: 0.5rem;
}

.crawl_btn:hover {
    background-color: #800080;
    color: #FFFFFF;
}

.url_table .page_url {
    white-space: nowrap;
}

.url_table {
    margin: 0;
    border: 2px solid #707070;
    border-radius: 20px !important;
    overflow: auto;
    max-height: 500px !important;
}

::-webkit-scrollbar {
    width: 3px;
    /* height: 300px !important; */
    z-index: -1;
    -webkit-overflow-scrolling: auto;
}

/* Track */
::-webkit-scrollbar-track {
    overflow: overlay;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #707070;
}

/* .url_table table.table {
    max-height: 500px !important;
} */

.url_table table thead {
    background-color: #FFFFFF;
}

.url_table table tr {
    height: 60px;
    vertical-align: middle;
}

.url_table table tr input {
    margin-left: 1rem;
}

.url_table table tbody tr {
    background-color: #F8F8F8;
}

.delete_icon {
    cursor: pointer !important;
}

.url_table table tr input,
.url_table table tbody tr .delete_icon {
    cursor: pointer !important;
}

.url_table table tr input[type=checkbox] {
    accent-color: #800080;
}

.btn.crawl_btn.disabled {
    background-color: #707070 !important;
    color: #FFFFFF !important;
    cursor: not-allowed;
}

a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
}

.textarea_container a:hover,
.textarea_container a:active,
.add_more_btn:active {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    background-color: #707070 !important;
}

@media screen and (max-width: 991px) {
    .url_table table {
        overflow-x: scroll !important;
    }
}

@media screen and (max-width: 576px) {

    .website_container {
        padding: 1rem;
    }

    .crawl_btn {
        font-size: 16px;
        height: 38px;
        padding-bottom: 0.4rem;
        width: 120px;
    }

    .crawl_btn img {
        width: 22px;
        height: 22px;
    }

    .website_container .url_input {
        flex-direction: column;
        align-items: flex-end;
        ;
    }

    .url_table table.table {
        width: auto;
        overflow-x: auto !important;
    }
}

/* -----------------------------------Create Options Header Styling---------------------------------------- */

.create_options_header {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 768px) {
    .create_options_header {
        align-self: flex-start;
        gap: 0.8rem;
    }

    .icon_text_div {
        padding-right: 0;
    }

    .icon_text_div h4 {
        margin-left: 0;
    }

    .icon_text_div img {
        width: 40px;
    }
}

@media screen and (max-width: 450px) {
    .textarea_head {
        padding: 0;
    }
}

.remove_data_source {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

@media screen and (max-width: 680px) {
    .remove_data_source {
        justify-content: flex-start;
        padding: 1rem 0;
    }

    .create_chatbot_footer_div {
        padding: 1.5rem 0.8rem !important;
    }
}

.remove_data_source .remove_btn {
    color: red;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    white-space: nowrap;
    gap: 10px;
    cursor: pointer;
}

.remove_data_source .remove_btn p {
    margin: 0;
}

.remove_data_source .remove_btn:hover {
    color: red;
}

.cancel_btn {
    background-color: #800080;
    color: #FFFFFF;
    font-weight: 500;
    border: none;
}

.cancel_btn:active,
.cancel_btn:hover {
    background-color: #620062;
}

.confirm_btn {
    background-color: #F58634;
    color: #FFFFFF;
    font-weight: 500;
    border: none;
}

.confirm_btn:active,
.confirm_btn:hover {
    background-color: #ca6c29;
}

.react_tooltip {
    width: 300px !important;
    text-align: left !important;
    background-color: #ffffff !important;
    color: #000 !important;
    font-size: 17px !important;
    font-weight: 500 !important;
    opacity: 1 !important;
    border-radius: 10px !important;
    padding: 1rem !important;
}

.hidden_tabs {
    display: none !important;
}

.hidden_dropdown {
    display: none !important;
}

.hidden_character {
    display: none !important;
}

.display_block_important {
    display: block !important;
}

.text_tab,
.file_tab,
.website_tab {
    display: none;
}

.uplaoding_opacity {
    opacity: 0.6;
}

.loader_table_div {
    border-radius: 20px !important;
    margin-top: 3rem;
    overflow: hidden;
}

.loader_round {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #800080;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

.loader_round_crawl {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #800080;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

.linear_loader {
    width: 100%;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -1800px 0
    }

    100% {
        background-position: 1800px 0
    }
}

.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 5%, #800080 25%, #eeeeee 50%);
    background-size: 100% 50px;
    height: 6px;
    position: relative;
}

.table_footer {
    margin-top: 2rem;
}

.table_footer .footer_row td {
    font-size: 16px;
    font-weight: 700;
}

.table_footer .table_footer_td_bg {
    background-color: #ffffff !important;
}