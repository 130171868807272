.admin_conversation_head_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin_conversation_head_container .admin_conversation_head {
    display: flex;
    align-items: center;
}

.admin_conversation_head_container .sidebar_toggle_btn {
    background-color: #FDF2FF;
    /* background-color: #000; */
    width: 100px;
    text-align: end;
    position: relative;
    left: -40px;
    align-self: flex-start;
}

.admin_conversation_head_container .admin_conversation_head h3 {
    margin: 0;
}

.admin_conversation_head_container .date_filter {
    border: 1px solid #000;
    width: 280px;
    border-radius: 7px;
    position: relative;
}

.admin_conversation_head_container .date_filter input {
    font-weight: 500;
    height: 38px;
    font-size: 15px;
}

.admin_conversation_head_container .calender_icon {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .conversation_container .date_filter_div {
        display: flex;
        justify-content:flex-end;;
        margin-top: 3rem;
    }

    .admin_conversation_head_container {
        flex-direction: column;
        gap: 1rem;        
    }

    /* .admin_conversation_head_container .admin_conversation_head{
        align-self: flex-start;
    } 

    .admin_conversation_head_container .date_filter_div {
        align-self: flex-end !important;
    } */
}