.admin_login_container {
    background-color: #ffffff;
    border-radius: 13px;
}

.err_msg {
    color: red;
    font-size: 15px;
    margin: 0;
    margin-left: 1rem;
}

.admin_login_wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin_login_container {
    padding:2rem 1rem;
}

.admin_login_container h2 {
    margin:0 0 1.5rem 0;
}

.admin_login_container .input_fields {
    margin: 2rem 0 2rem 0;
    padding: 0 0.5rem;
}

.admin_login_container .input_fields input {
    width: 100%;
    height: 40px;
    background-color: #fcecff;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 0.2rem 1rem;
}

.admin_login_container .login_with_email p {
    font-size: 14px;
    font-weight: 400;
}

.admin_login_container .input_fields .password_input {
    position: relative;
}
.admin_login_container .input_fields .password_input .password_toggle_icon {
    position: absolute;
    top: 7px;
    right: 12px;
}

.admin_login_container .forgot_remember_div {
    margin-top: 0.5rem;
    padding-right: 1rem;
}

.admin_login_container .forgot_remember_div div {
    display: flex;
    justify-content:flex-end;;
    align-items: center;
}

.admin_login_container .forgot_remember_div div a {
    text-decoration: none;
    color: #800080;
}

.admin_login_container .login_btn {
    border: 2px solid #000;
    background-color: #ffffff;
    font-weight: 700;
    padding: 0.4rem 2rem;
    transition: all 0.3s ease-in-out;
}

.admin_login_container .login_btn:hover {
    background: linear-gradient(to right, #800080, #FC9E4C);
    color: #ffffff;
}