.logo_div {
    width: 200px;
}

.logo_div img {
    width: 100%;
}

.nav_bg {
    background-color: #FDF2FF;
    padding: 1rem 4rem !important;
}

.navbar_container {
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
}

#navbarNavAltMarkup ul {
    width: 60%;
    justify-content: space-between;
}

#navbarNavAltMarkup li a {
    font-size: 18px;
    white-space: nowrap;
    font-weight: 600;
    color: #000000;
}

.sign_up_btn a {
    padding: 0;
}

.sign_up_btn {
    background: linear-gradient(to right, #800080, #FC9E4C);
    border-radius: 30px;
    padding: 0 0.5rem;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.sign_up_btn a {
    color: #FFFFFF !important;
    font-size: 18px !important;
    border: none;
}

.profile_menu {
    background: linear-gradient(to right, #800080, #FC9E4C);
    color: #FFFFFF !important;
    font-weight: 500;
    border-radius: 50px;
    background-color: none;
    border: none;
}

 .dropdown-menu {
    margin-top: 1.8rem;
    width: 250px;
    text-align: end;
    padding: 1rem 0.5rem;
    border: none;
    position: relative;
}

.dropdown-item {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.dropdown-item:active{
    background-color: #fff;
    color: #000;
}

.dropdown-item img {
    width: 20px;
    margin-right: 0.4rem;
}

.triangle_pointer {
    width: 14px;
    height: 18px;
    border-top: solid 19px #676767;
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
    position: absolute;
    top: -20px;
    right: 48px;
}

.dropdown-item a {
    text-decoration: none;
    background-color: none;
}

@media screen and (min-width: 1280px) {
    #navbarNavAltMarkup ul {
        width: 55%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1280px) {
    #navbarNavAltMarkup ul {
        width: 80%;
        justify-content: space-between;
    }
}

@media screen and (min-width: 991px) {
    .faq {
        margin-right: 1.5rem;
    }

    .login {
        margin-right: 1rem;
    }

}

@media screen and (max-width: 991px) {

    #navbarNavAltMarkup ul {
        align-items: flex-end;;
        width: 200px;
        text-align: right;
        margin-right: 0;
        margin-left: auto;
        border-radius: 16px;
        padding: 0.5rem;
    }

    .sign_up_btn {
        width: 100px;
    }

    .nav_bg {
        padding: 1rem 1.5rem !important;
    }
}

@media screen and (max-width: 576px) {
    .logo_div {
        width: 160px;
    }

    .login {
        margin-bottom: 0.5rem;
    }

    #navbarNavAltMarkup ul {
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;;
        width: 200px;
        text-align: right;
        margin-right: 0;
        margin-left: auto;
        background-color: #FDF2FF !important;
        border-radius: 16px;
        padding: 0.5rem;
        box-shadow: none !important;
    }

    .sign_up_btn {
        width: 100px;
    }
}

.header_container .dropdown-menu {
    margin-top: 1.8rem;
    width: 250px;
    text-align: end;
    padding: 1rem 0.5rem;
    border: none;
    position: relative;
}

.header_container.dropdown-item.dropdown_style {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.header_container .dropdown-item.dropdown_style:active{
    background-color: #fff;
    color: #000;
}

.header_container .dropdown-item.dropdown_style img {
    width: 20px;
    margin-right: 0.4rem;
}

.header_container .dropdown-item.dropdown_style a {
    text-decoration: none;
    background-color: none;
}

.hidden_header {
    display: none;
}