.setting_wrapper {
    margin: 2rem 1rem;
}

.setting_container {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.setting_container .setting_tabs {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin: 2rem 0 0.8rem 0;
}

.setting_container .setting_tabs p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
}

.setting_container .tab_border {
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: linear-gradient(to right, #800080 , #F58634);
}

.setting_container  .sidebar_toggle_btn {
    background-color: #800080 !important;
    color: #ffffff !important;
    width: 70px;
    height: fit-content;
    text-align: right;
    border: none;
    position: relative;
    left: -38px;
}

.setting_container .sidebar_toggle_btn:active {
    background-color: #800080 !important;
    color: #ffffff !important;
    border: none !important;
}

.setting_container .chatbox_head_left {
    display: flex;
    align-items: center;
}

.setting_container .chatbox_head_left h2 {
    margin-bottom: 0;
}

.active_tab {
    background-color: #ffffff;
    padding: 0.2rem 0.7rem;
    border-radius: 20px;
}

.setting_container .setting_tabs p {
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .setting_container {
        gap: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .setting_container {
        gap: 0;
        padding: 0;
    }
}