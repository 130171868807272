.pricing {
    background-color: #EEF1F3;
    height: auto;
}

.pricing_contaner .pricing_heading {
    margin-top: 5rem;
    margin-bottom: 2.5rem;
}

.pricing_contaner .pricing_heading h4 {
    font-weight: 350 !important;
}

.pricing_cards_container .card-body {
    padding: 1rem 0.3rem 0.5rem 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing_cards_container .card-body .free_text {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.pricing_cards_container .card-body .card-title {
    font-size: 24px;
    text-align: center;
}

.pricing_cards_container .card-body .card_horizon {
    height: 4px;
    background: linear-gradient(to right, #800080, #FC9E4C);
    border-radius: 10px;
}

.pricing_cards_container .card-body ul {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
}

.pricing_cards_container .card-body ul li {
    margin: 0.4rem 0;
}

.pricing_cards_container .card-body ul li p {
    font-size: 14px;
    font-weight: 500;
}

.pricing_cards_container .card {
    max-width: 280px;
    border: none;
    border-radius: 12px;
    margin-bottom: 1rem;
    min-height: 400px;
}

.pricing_cards_container .pricing_signup_btn a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
}

@media screen and (max-width: 1200px) {
    .pricing_cards_container .card {
        width: 300px;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 576px) {
    .pricing_contaner .pricing_heading h1 {
        font-size: 25px;
    }

    .pricing_contaner .pricing_heading h4 {
        font-size: 18px;
    }

    .pricing_contaner .pricing_heading {
        margin-top: 4rem;
        margin-bottom: 2.5rem;
    }

    .pricing_cards_container .card_1 {
        height: 398px;
    }

}


.pricing_signup_btn button {
    border: none !important;
    box-shadow: none !important;
    background-color: #707070 !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    width: 100% !important;
    height: 100% !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 0.4rem 1rem;
}

.pricing_signup_btn button:active {
    background-color: #707070 !important;
}

.pricing_cards_container .pricing_signup_btn {
    background-color: #707070 !important;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: center;
}

.successful_screen_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.successful_screen_btn button {
    border: none !important;
    box-shadow: none !important;
    background: linear-gradient(to right, #800080, #FC9E4C);
    color: #ffffff;
    font-weight: 500;
    padding: 0.3rem 1rem;
    border-radius: 20px;
}

.signup_btn_disabled {
    opacity: 0.7;
    pointer-events: none !important;
    cursor: not-allowed !important;
}

a {
    text-decoration: none !important;
}